<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section id="version-content" class="container">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <th scope="row">Version</th>
              <td id="version">{{ version }}</td>
            </tr>
            <tr>
              <th scope="row">Version date</th>
              <td id="version-date">2024-06-25 03:27:11 EDT</td>
            </tr>
            <tr>
              <th scope="row">Environment name</th>
              <td id="environment-name">{{ environmentName }}</td>
            </tr>
            <tr>
              <th scope="row">Build number</th>
              <td id="build-number">5</td>
            </tr>
            <tr>
              <th scope="row">Git branch</th>
              <td id="git-branch">release_invictus_06_25_2024</td>
            </tr>
            <tr>
              <th scope="row">Git commit hash</th>
              <td id="git-commit">368f1a70af17c949020eb5c9b1314dfc039072da</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</div>
